import React, { useState, } from 'react';
import { Button, Toolbar, IconButton, MenuItem, Select, Menu } from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import momentUtils from '@date-io/moment'
import { NavigateBefore, NavigateNext, AccountBox, Menu as MenuIcon } from "@material-ui/icons"
import TaskFormModal from "./TaskFormModal";
import FetchButton from "../containers/FetchButton";
import ProfileSettingsModal from "../containers/ProfileSettingsModal";
import useStyles from "./styles/toolbarStyles"

export default (toolbar) => {

    const [calendarView, setCalendarView] = useState(toolbar.view)
    const [menuAnchor, setMenuAnchor] = useState(null);

    const goToBack = () => {
        switch (calendarView) {
            case "month":
                toolbar.date.setMonth(toolbar.date.getMonth() - 1);
                toolbar.onNavigate("prev");
                toolbar.fetchTasks(toolbar.date);
                break;
            case "week":
                toolbar.date.setDate(toolbar.date.getDate() - 7);
                toolbar.onNavigate("prev");
                break;
            default:
                break;
        }
    };

    const goToNext = () => {
        switch (calendarView) {
            case "month":
                toolbar.date.setMonth(toolbar.date.getMonth() + 1);
                toolbar.onNavigate("next");
                toolbar.fetchTasks(toolbar.date);
                break;
            case "week":
                toolbar.date.setDate(toolbar.date.getDate() + 7);
                toolbar.onNavigate("next");
                break;
            default:
                break;
        }
    };

    const menuOpenHandler = (event) => {
        setMenuAnchor(event.currentTarget);
    };

    const menuCloseHandler = () => {
        setMenuAnchor(null);
    };

    const goToCurrent = () => {
        const now = new Date();
        toolbar.date.setMonth(now.getMonth());
        toolbar.date.setYear(now.getFullYear());
        toolbar.date.setDate(now.getDate());
        toolbar.onNavigate('current');
        toolbar.fetchTasks(toolbar.date);
    };

    const changeMonthHandler = (event) => {
        toolbar.date.setMonth(event._d.getMonth());
        toolbar.onNavigate();
        toolbar.fetchTasks(toolbar.date);
    };

    const changeYearHandler = (event) => {
        toolbar.date.setYear(event._d.getFullYear());
        toolbar.onNavigate();
        toolbar.fetchTasks(toolbar.date);
    };

    const changeCalendarViewHandler = ({ target: { value } }) => {
        setCalendarView(value);
        toolbar.onView(value);
    };

    const classes = useStyles()

    return (
        <Toolbar style={{height : '7vh'}}>
            <div >
                <IconButton onClick={toolbar.openDrawerHandler} className={classes.menuButton}>
                    <MenuIcon />
                </IconButton>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={goToCurrent}
                    id="navigate-current-button"
                    className={classes.menuButton}
                >
                    Today
                </Button>
                <IconButton aria-label="previous" onClick={goToBack} id="navigate-prev-month-button" disabled={toolbar.date <= new Date(2020, 0, 31)}>
                    <NavigateBefore />
                </IconButton>
                <IconButton aria-label="next" onClick={goToNext} id="navigate-next-month-button" disabled={toolbar.date >= new Date(new Date().getFullYear() + 15, 11, 1)} className={classes.menuButton}>
                    <NavigateNext />
                </IconButton>
                </div>
                <div className={classes.toolbarLeft}>
                <MuiPickersUtilsProvider utils={momentUtils}>
                    <DatePicker
                        views={['year']}
                        inputVariant='outlined'
                        variant='inline'
                        format="YYYY"
                        disableToolbar
                        minDate={new Date(2019, 12, 1)}
                        maxDate={new Date(new Date().getFullYear() + 15, 11, 31)}
                        value={toolbar.date}
                        onChange={changeYearHandler}
                        className={classes.picker}
                        inputProps={{
                            style: {
                                textAlign: 'center'
                            }
                        }}
                    />
                    <DatePicker
                        views={['month']}
                        inputVariant='outlined'
                        variant='inline'
                        format="MMMM"
                        disableToolbar
                        value={toolbar.date}
                        onChange={changeMonthHandler}
                        className={classes.picker}
                        inputProps={{
                            style: {
                                textAlign: 'center'
                            }
                        }}
                    />
                </MuiPickersUtilsProvider>
                <Select value={calendarView} variant="outlined" onChange={changeCalendarViewHandler} id="view-type-select" className={classes.picker}>
                    <MenuItem value={"month"} id="month-menuitem">Month</MenuItem>
                    <MenuItem value={"week"} id="week-menuitem">Week</MenuItem>
                </Select>
                </div>
            <h1>LOGO</h1>
            <div className={classes.toolbarRight}>
                <TaskFormModal />
                <FetchButton calDate={toolbar.date} />
                <IconButton aria-label="profile" onClick={menuOpenHandler} id="profile-menu-button">
                    <AccountBox color="primary" fontSize="large" />
                </IconButton>
                <Menu
                    id="profile-menu"
                    anchorEl={menuAnchor}
                    keepMounted
                    open={Boolean(menuAnchor)}
                    onClose={menuCloseHandler}
                >
                    <MenuItem style={{ display: "none" }}></MenuItem>
                    <ProfileSettingsModal menuCloseHandler={menuCloseHandler} />
                    <MenuItem onClick={toolbar.logoutHandler} id="logout-menuitem">Logout</MenuItem>
                </Menu>
            </div>
        </Toolbar >
    );
};