import { ACCOUNT_DETAILS_FETCHING, ACCOUNT_DETAILS_FETCHED, ACCOUNT_DETAILS_ERROR, } from '../actions/accountDetails';

const initState = {
    loading: false,
    accountDetails: {},
    accountError: ''
}

export default (state = initState, action) => {
    switch (action.type) {
        case ACCOUNT_DETAILS_FETCHING:
            return { ...state, loading: true };
        case ACCOUNT_DETAILS_FETCHED:
            return { ...state, loading: false, accountDetails: action.payload };
        case ACCOUNT_DETAILS_ERROR:
            return { ...state, loading: false, accountError: action.payload };
        default:
            return state;
    }
}