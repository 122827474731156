import React from 'react';
import { Drawer, Divider, IconButton, List, ListItem, Checkbox } from '@material-ui/core'
import { ChevronLeft } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
}))


export default ({ isOpen, closeHandler, tags, setDisplayTag, annualColor }) => {

    const classes = useStyles()

    const checkboxHander = ({ target: { name, checked } }) => {
        setDisplayTag(name, checked)
    }

    return (
        <Drawer open={isOpen} variant="persistent">
            <div style={{ minWidth: "10vw", maxWidth: "10vw" }}>
                <div className={classes.drawerHeader}>
                    <IconButton onClick={closeHandler}>
                        <ChevronLeft />
                    </IconButton>
                </div>
                <Divider />
                <div>
                    <List>
                        <ListItem key='Annual' style={{color: annualColor}}>
                            <Checkbox name="Annual" onChange={checkboxHander} defaultChecked style={{ color: annualColor }} /> Annual
                        </ListItem>
                        {tags.map((tag, index) => (
                            <ListItem key={index} style={{ color: tag.color }}>
                                <Checkbox name={tag.name} onChange={checkboxHander} defaultChecked style={{ color: tag.color }} /> {tag.name}
                            </ListItem>
                        ))}
                    </List>
                </div>
            </div>
        </Drawer>
    )
}