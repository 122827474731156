import Calendar from "../components/Calendar"
import { fetchTasks, taskError, } from "../redux/actions/calendar"
import { fetchTags, fetchWorkHours, fetchPrivateHours } from "../redux/actions/profile"
import { fetchAccountDetails } from "../redux/actions/accountDetails"
import { connect } from "react-redux"

const mapState = (state, ownProps) => ({
    tasks: state.calendar.tasks,
    error: state.calendar.error,
    taskDetails: state.calendar.taskDetails,
    tagsToDisplay: state.profile.tagsToDisplay,
    ownProps,
})
const mapDispatch = (dispatch) => ({
    fetchTasks: (calDate) => dispatch(fetchTasks(calDate)),
    fetchWorkHours: () => dispatch(fetchWorkHours()),
    fetchPrivateHours: () => dispatch(fetchPrivateHours()),
    fetchTags: () => dispatch(fetchTags()),
    fetchAccountDetails: () => dispatch(fetchAccountDetails()),
    taskError: () => dispatch(taskError("")),
})

export default connect(mapState, mapDispatch)(Calendar)