import Welcome from "../components/Welcome"
import { connect } from "react-redux"
import {loadUserHandler} from "../redux/actions/auth"

const mapState = (state) => ({
    auth : state.auth
})
const mapDispatch = (dispatch) => ({
    loadUser : token => dispatch(loadUserHandler(token)),
})
export default connect(mapState, mapDispatch)(Welcome)