import { connect } from "react-redux";
import { setDisplayTag } from "../redux/actions/profile"
import Drawer from "../components/Drawer";

const mapState = (state, ownProps) => ({
    tags: state.profile.tags,
    annualColor: state.accountDetails.accountDetails.annual_tag_color,
    ownProps
})

const mapDispatch = (dispatch) => ({
    setDisplayTag: (name, checked) => dispatch(setDisplayTag(name, checked))
})

export default connect(mapState, mapDispatch)(Drawer);