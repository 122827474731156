import React from 'react';
import FacebookLogin from 'react-facebook-login';

const FacebookSocialAuth = ({facebookLoginHandler}) => {
    const fbResponse = (response) => {
      facebookLoginHandler(response.accessToken);
    }
    return (
      <div className="App">
        <h1>LOGIN WITH FACEBOOK</h1>

        <FacebookLogin
          textButton="LOGIN WITH FACEBOOK"
          appId= "838345106803327"
          fields="name,email,picture"
          callback={fbResponse}
        />
      </div>
    );
  }


export default FacebookSocialAuth;