import { makeStyles } from "@material-ui/core/styles"

export default () => {
    const useStyles = makeStyles((theme) => ({
        modal: {
            display: "block",
            alignItems: "center",
            justifyContent: "center",
            maxHeight: "80%",
            maxWidth: "52.5vh",
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "5vh",
            marginBottom: "auto"
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            border: "2px solid #000",
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        },
        centerButtons : {
            marginRight: "auto",
            marginLeft: "auto",
            display: "flex",
            justifyContent: "center"
        },
        table: {
            minWidth: "100%",
        },
        hidden: {
            display: 'none',
        },
    }))

    return useStyles()
}