import SmartTaskFrom from "../components/EditSmartTaskForm"
import { putSmartTask,} from "../redux/actions/calendar"
import { connect } from "react-redux"

const mapState = (state) => ({
    tags: state.profile.tags,
})

const mapDispatch = (dispatch) => ({
    putSmartTask: (data) => dispatch(putSmartTask(data)),
})

export default connect(mapState, mapDispatch)(SmartTaskFrom)