import React, { useState } from 'react';
import { Modal, Backdrop, Fade, Button, } from "@material-ui/core";
import useStyles from "./styles/ModalStyles";
import AddSmartTaskForm from "../containers/AddSmartTaskForm";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import AddNormalTaskForm from "../containers/AddNormalTaskForm";
import AddAnnualForm from "../containers/AddAnnualForm"

export default () => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [task, setTask] = useState("smart")

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const aligmentHandler = (event, newAligment) => {
        if (newAligment !== null) {
            setTask(newAligment)
        }
    }

    const renderForm = (task) => {
        switch (task) {
            case "smart":
                return <AddSmartTaskForm closeHandler={handleClose} />;
            case "normal":
                return <AddNormalTaskForm closeHandler={handleClose} />;
            case "annual":
                return <AddAnnualForm closeHandler={handleClose} />;
            default:
                return <AddNormalTaskForm closeHandler={handleClose} />;
        }
    }

    return (
        <div style={{display:'inline'}}>
            <Button variant="contained" color="default" onClick={handleOpen} id="task-form-button">
                Add Task
            </Button>
            <Modal
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <h2>Add Task</h2>
                        <ToggleButtonGroup
                            value={task}
                            exclusive
                            onChange={aligmentHandler}
                            aria-label="task-aligment"
                            className={classes.centerButtons}
                        >
                            <ToggleButton value="smart" aria-label="smart task" color="primary">
                                Smart Task
                            </ToggleButton>
                            <ToggleButton value="normal" aria-label="normal task" color="primary">
                                Normal Task
                            </ToggleButton>
                            <ToggleButton value="annual" aria-label="annual event" color="primary">
                                Annual Event
                            </ToggleButton>
                        </ToggleButtonGroup>
                        {renderForm(task)}
                    </div>
                </Fade>
            </Modal>
        </div>
    )

}