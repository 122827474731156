import { connect } from "react-redux"
import UserTagsList from "../components/UserTagsList"
import { addNewTag, updateTag } from "../redux/actions/profile"
import { patchAnnualColor } from "../redux/actions/accountDetails"

const mapState = (state, ownProps) => ({
    tags : state.profile.tags,
    annualColor : state.accountDetails.accountDetails.annual_tag_color,
    ownProps
})

const mapDispatch = (dispatch) => ({
    updateTag : (data) => dispatch(updateTag(data)),
    addNewTag : (data) => dispatch(addNewTag(data)),
    patchAnnualColor : (data) => dispatch(patchAnnualColor(data)),
})

export default connect(mapState, mapDispatch)(UserTagsList)