import React, { useState } from 'react';
import { Modal, Backdrop, Fade, Button,} from "@material-ui/core";
import useStyles from "./styles/ModalStyles";
import EditNormalTaskForm from "../containers/EditNormalTaskForm";

export default ({taskData, closeHandler}) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        closeHandler();
    };


    return (
        <div>
            <Button variant="contained" color="default" onClick={handleOpen} id="edit-smart-task-button">
                Edit
            </Button>
            <Modal
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                            <h2>Edit Normal Task</h2>
                        <EditNormalTaskForm closeHandler={handleClose} taskData={taskData} />
                    </div>
                </Fade>
            </Modal>
        </div>
    )

}