import moment from 'moment';

export const serializeData = (data) => {
    const copy = data.map((el) => ({
        title: el.name,
        start: moment(`${el.date} ${el.start}`).toDate(),
        startTime: el.start.slice(0, -3),
        end: moment(`${el.date} ${el.end}`).toDate(),
        endTime: el.end.slice(0, -3),
        id: el.id,
        taskDate: el.date,
        tag: el.tag,
        taskId: el.task_id,
        type: "task",
    }))
    return copy
}

export const selectedDays = (days) => (
    Object.keys(days).filter((day) => days[day])
)

export const serializeTaskDetails = (data) => {
    const translatePriority = (priority) => {
        switch (priority) {
            case 1:
                return "Low";
            case 2:
                return "Medium";
            case 3:
                return "High";
            case 4:
                return "Top"
            default:
                break;
        }
    }
    if (data.manual === false) {
        const serializedData = {
            "manual": data.manual,
            "title": data.name,
            "dateFrom": data.date_from,
            "dateTo": data.date_to,
            "description": data.description,
            "translatedPriority": translatePriority(data.priority),
            "priority": data.priority,
            "duration": data.duration_in_time,
            "maxPartLength": data.max_part_length.slice(0, -3),
            "tag": data.taskparts[0].tag,
            "tagId": data.taskparts[0].tag.id,
            "taskParts": data.taskparts,
            "days": data.days,
            "taskId": data.id,
            "parted" : data.parted,
        };
        return serializedData;
    } else {
        const serializedData = {
            "manual": data.manual,
            "title": data.name,
            "date": data.taskparts[0].date,
            "description": data.description,
            "duration": data.duration_in_time,
            "tag": data.taskparts[0].tag,
            "start": data.taskparts[0].start.slice(0, -3),
            "end": data.taskparts[0].end.slice(0, -3),
            "taskId": data.id,
            "forceOverride": data.force_override,
        };
        return serializedData;
    }
}

export const serializeAnnuals = (data, calDate) => {
    const copy = data.map((annualEvent) => ({
        title: annualEvent.title,
        start: new Date(calDate.getFullYear(), annualEvent.month-1, annualEvent.day,0,0),
        end: new Date(calDate.getFullYear(), annualEvent.month-1, annualEvent.day, 23, 59),
        date : new Date(calDate.getFullYear(), annualEvent.month-1, annualEvent.day,0,0).toISOString().slice(0,10),
        taskDate : new Date(calDate.getFullYear(), annualEvent.month-1, annualEvent.day, 11, 59).toISOString().slice(0,10),
        allDay : true,
        tag: {
            name: "Annual",
            color: annualEvent.annual_color,
            id: 2
        },
        type: "annual",
        taskId : annualEvent.id
    }))
    return copy
}

export const serializeSingleAnnual = (annualEvent, date) => ({
    title: annualEvent.title,
    start: new Date(date.getFullYear(), annualEvent.month-1, annualEvent.day,0,0),
    end: new Date(date.getFullYear(), annualEvent.month-1, annualEvent.day, 23, 59),
    date : new Date(date.getFullYear(), annualEvent.month-1, annualEvent.day,0,0).toISOString().slice(0,10),
    allDay : true,
    tag: {
        name: "Annual",
        color: annualEvent.annual_color,
        id: 2
    },
    type: "annual",
    taskId : annualEvent.id
})

export const serializeAnnualDetails = (data) => {
    const calDate = new Date()

    return {
        title: data.title,
        date : new Date(calDate.getFullYear(), data.month-1, data.day,12,0).toISOString().slice(5,10),
        allDay : true,
        tag: {
            name: "Annual",
            color: data.annual_color,
            id: 2
        },
        type: "annual",
        taskId : data.id
    }

}