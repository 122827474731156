import { USER_LOADED, USER_LOADING, LOGIN_FAILED, LOGIN_SUCCESSFUL, LOGOUT_SUCCESSFUL, AUTHENTICATION_ERROR } from "../actions/auth"

const initState = {
    token: localStorage.getItem("token"),
    isAuthenticated: false,
    isLoading: false,
    user: null,
    errors: [],
};

export default (state = initState, action) => {
    switch (action.type) {
        case USER_LOADING:
            return { ...state, isLoading: true };
        case USER_LOADED:
            return { ...state, isAuthenticated: true, isLoading: false, errors: null, user: action.payload };
        case LOGIN_SUCCESSFUL:
            localStorage.setItem('token', action.payload.token);
            return { ...state, token: action.payload.token, user: action.payload.user.username, isAuthenticated: true, isLoading: false, errors: null };
        case LOGIN_FAILED:
            return { ...state, isAuthenticated: false, isLoading: false, errors: [...state.errors, action.payload] };
        case AUTHENTICATION_ERROR:
            return {...state, isAuthenticated:false, isLoading:false, errors: [...state.errors, action.payload]}
        case LOGOUT_SUCCESSFUL:
            localStorage.removeItem("token");
            return {...state, token:null, user:null, isAuthenticated:false, isLoading:false, errors:[]}
        default:
            return state
    };
};