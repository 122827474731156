import React, { useState } from 'react';
import useStyles from "./styles/FormStyles";
import { Button, Container, CssBaseline, Grid, TextField, } from "@material-ui/core";
import { API } from '../globals';
import AreYouSureModal from './AreYouSureModal';

export default ({closeHandler}) => {

    const [state, setState] = useState({
        new_password1: "",
        new_password2: "",
        old_password: "",
    });

    const apiPasswordChangeCall = async () => {
        const token = localStorage.getItem('token')
        let headers = { "Content-Type": "application/json" }
        if (token) {
            headers["Authorization"] = `JWT ${token}`
        }
        const packToSend = {
            method: "POST",
            headers: headers,
            body: JSON.stringify({ ...state })
        }
        await fetch(`${API}/rest-auth/password/change/`, packToSend)
            .then(resp => resp.json())
            .then(resp => console.log(resp))
            .catch(resp => console.log(resp))
    };

    const onSubmitHandler = (event) => {
        event.preventDefault()
    }

    const onChangeHandler = ({ target: { name, value } }) => {
        setState(prevState => ({
            ...prevState,
            [name]: value
        }))
    }

    const classes = useStyles()

    const triggerButton = ({openHandler}) => (<Button variant="contained" color="secondary" onClick={openHandler} id="change-password" type='submit'>Change Password</Button>)

    return(
        <Container component='main' maxWidth='xs'>
            <CssBaseline />
            <div className={classes.paper}>
                <form className={classes.form} onSubmit={onSubmitHandler}>
                    <Grid item xs={12}>
                    <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            autoFocus
                            onChange={onChangeHandler}
                            value={state.old_password}
                            id="old_password"
                            name="old_password"
                            label="Current password"
                            type="password"
                        />
                    </Grid>
                    <Grid item xs={12}>
                    <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            onChange={onChangeHandler}
                            value={state.new_password1}
                            id="new_password1"
                            name="new_password1"
                            label="New password"
                            type="password"
                        />
                    </Grid>
                    <Grid item xs={12}>
                    <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            onChange={onChangeHandler}
                            value={state.new_password2}
                            id="new_password2"
                            name="new_password2"
                            label="Repeat new password"
                            type="password"
                        />
                    </Grid>
                    <AreYouSureModal apiAsyncFunction={apiPasswordChangeCall} TriggerButton={triggerButton} />
                </form>
            </div>
        </Container>
    )
}

