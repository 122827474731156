import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles'
import { Modal, Fade, Backdrop, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableFooter, TablePagination } from "@material-ui/core";
import { Done, Star } from '@material-ui/icons';
import useStyles from "./styles/ModalStyles";

export default ({ open, closeHandler, date, tasks }) => {

    const classes = useStyles();

    const [page,setPage] = useState(0);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }

    const StyledTableCell = withStyles((theme) => ({
        head: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
            fontSize: 14,
        },
        body: {
            fontSize: 14,
            width: "auto",
        }
    }))(TableCell);

    const isPast = (part) => (new Date() >= createDateTime(part))

    const createDateTime = (part) => {
        const day = part.taskDate.slice(8, 10)
        const month = part.taskDate.slice(5, 7) - 1
        const year = part.taskDate.slice(0, 4)
        const hours = part.endTime.slice(0, 2)
        const minutes = part.endTime.slice(3, 5)

        return new Date(year, month, day, hours, minutes)
    }


    return (
        <>
            <Modal
                className={classes.modal}
                open={open}
                onClose={closeHandler}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <TableContainer>
                            <Table className={classes.table}>
                                <TableBody>
                                    <TableRow>
                                        <StyledTableCell align='center' style={{ width: "100%" }}>{date}</StyledTableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                            <Table className={classes.table}>
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align='left'>Done</StyledTableCell>
                                        <StyledTableCell align='left'>Title</StyledTableCell>
                                        <StyledTableCell align='left'>Start</StyledTableCell>
                                        <StyledTableCell align='left'>End</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        tasks.slice(page * 7, page * 7 + 7).map((task) => {
                                            if (task.type === 'task') {
                                                return (
                                                    <TableRow key={task.id}>
                                                        <StyledTableCell>{isPast(task) ? <Done color='primary' /> : ""}</StyledTableCell>
                                                        <StyledTableCell>{task.title}</StyledTableCell>
                                                        <StyledTableCell>{task.startTime}</StyledTableCell>
                                                        <StyledTableCell>{task.endTime}</StyledTableCell>
                                                    </TableRow>
                                                )
                                            } else {
                                                return (
                                                    <TableRow key={`${task.taskId}${task.title}`}>
                                                        <StyledTableCell><Star style={{color : task.tag.color}} /></StyledTableCell>
                                                        <StyledTableCell colSpan={2} align='center'>{task.title}</StyledTableCell>
                                                        <StyledTableCell><Star style={{color : task.tag.color}} /></StyledTableCell>
                                                    </TableRow>
                                                )
                                            }
                                        })
                                    }
                                </TableBody>
                                <TableFooter className={tasks.length > 7 ? null : classes.hidden}>
                                    <TablePagination 
                                        rowsPerPageOptions={[7]}
                                        rowsPerPage={7}
                                        page={page}
                                        count={tasks.length}
                                        onChangePage={handleChangePage}
                                    />
                                </TableFooter>
                            </Table>
                        </TableContainer>
                    </div>
                </Fade>
            </Modal>
        </>
    );

}