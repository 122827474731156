import React, { useState } from 'react'
import { Button, CssBaseline, TextField, Grid, Container, Checkbox, MenuItem, InputLabel, FormControlLabel } from "@material-ui/core"
import useStyles from "./styles/FormStyles"


export default ({ putSmartTask, closeHandler, tags, taskData }) => {


    const [state, setState] = useState({
        name: taskData.title,
        date_from: taskData.dateFrom,
        date_to: taskData.dateTo,
        description: taskData.description,
        duration: taskData.duration,
        priority: taskData.priority,
        tag: taskData.tag.id,
        max_part_length: taskData.maxPartLength,
        parted: taskData.parted,
        days: {
            0: true,
            1: true,
            2: true,
            3: true,
            4: true,
            5: false,
            6: false
        },
        manual: false,
        date_of_request : new Date().toLocaleDateString().slice(0,10)
    })

    const [errors, setErrors] = useState({
        name: false,
        description: false,
        date_from: false,
        date_to: false,
        max_part_length: false,
    })


    const onSubmitHandler = event => {
        event.preventDefault()
        putSmartTask([state, taskData.taskId])
        closeHandler()
    }

    const onChangeHandler = ({ target: { name, value } }) => {
        setState(prevState => ({
            ...prevState,
            [name]: value
        }))
        const nameAndDescriptionRegEx = RegExp(/[a-zA-Z,.?!@"'()/-:+\n \p{L}]/mgu)
        switch (name) {
            case "name":
            case "description":
                if (value !== "") {
                    if (value.match(nameAndDescriptionRegEx) === null || value.match(nameAndDescriptionRegEx).length !== value.length) {
                        setErrors({
                            ...errors,
                            [name]: true
                        })
                    } else {
                        setErrors({
                            ...errors,
                            [name]: false
                        })
                    }
                }
                break;

            case "date_from":
                if (value < taskData.dateFrom) {
                    setErrors({
                        ...errors,
                        [name]: true
                    })
                } else {
                    setErrors({
                        ...errors,
                        [name]: false
                    })
                }
                break;

            case "date_to":
                if (value < state.date_from && value < new Date().toISOString().slice(0, 10)) {
                    setErrors({
                        ...errors,
                        [name]: true
                    })
                } else {
                    setErrors({
                        ...errors,
                        [name]: false
                    })
                }
                break;

            case "max_part_length":
                if (value > state.duration) {
                    setErrors({
                        ...errors,
                        [name]: true
                    })
                } else {
                    setErrors({
                        ...errors,
                        [name]: false
                    })
                }
                break;
            default:
                break;
        }
    }

    const checkboxHandler = ({ target: { name, checked } }) => {
        setState(prevState => ({
            ...prevState,
            [name]: checked
        }))
    }

    const daysCheckboxHandler = ({ target: { name, checked } }) => {
        setState(prevState => ({
            ...prevState,
            days: {
                ...prevState.days,
                [name]: checked
            }
        }))
    }

    const classes = useStyles()


    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <form className={classes.form} onSubmit={onSubmitHandler}>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="name"
                            label="Task name"
                            onChange={onChangeHandler}
                            value={state.name}
                            error={errors.name}
                            helperText={errors.name ? "Incorrect value" : ""}
                            name="name"
                            autoFocus
                        />
                    </Grid>
                    <Grid item xs={6} className={classes.inlineInput}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="dateFrom"
                            label="Date from"
                            onChange={onChangeHandler}
                            value={state.date_from}
                            error={errors.date_from}
                            // helperText={errors.date_from ? "Incorrect date" : ""}
                            // helperText="Cannot be changed"
                            name="date_from"
                            type="date"
                            disabled={true}
                            InputLabelProps={{
                                shrink: true
                            }}
                        />
                    </Grid>
                    <Grid item xs={6} className={classes.inlineInput}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="date_to"
                            label="Date to"
                            onChange={onChangeHandler}
                            value={state.date_to}
                            error={errors.date_to}
                            helperText={errors.date_to ? "Incorrect date" : ""}
                            name="date_to"
                            type="date"
                            InputLabelProps={{
                                shrink: true
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="description"
                            label="Description"
                            onChange={onChangeHandler}
                            value={state.description}
                            error={errors.description}
                            helperText={errors.description ? "Incorrect value" : ""}
                            name="description"
                            multiline
                            rowsMax={4}
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid item xs={6} className={classes.inlineInput}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="duration"
                                label="Duration"
                                error={state.duration !== "" && !state.duration.match("^[0-9]{1,3}:[0-5][0-9]$")}
                                helperText={state.duration !== "" && !state.duration.match("^[0-9]{1,3}:[0-5][0-9]$") ? "Please use correct time format (HH:MM)" : ""}
                                onChange={onChangeHandler}
                                value={state.duration}
                                name="duration"
                            />
                        </Grid>
                        <Grid item xs={6} className={classes.inlineInput}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="max_part_length"
                                label="Maximum length of task part"
                                onChange={onChangeHandler}
                                value={state.max_part_length}
                                error={errors.max_part_length}
                                helperText={errors.max_part_length ? "Max part lenght cant be higher than duration" : ""}
                                name="max_part_length"
                                type="time"
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid item xs={6} className={classes.inlineInput}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="priority"
                                label="Priority"
                                onChange={onChangeHandler}
                                value={state.priority}
                                name="priority"
                                select
                            >
                                <MenuItem value={1}>Low</MenuItem>
                                <MenuItem value={2}>Medium</MenuItem>
                                <MenuItem value={3}>High</MenuItem>
                                <MenuItem value={4}>Top</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={6} className={classes.inlineInput}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="tag"
                                label="Tag"
                                onChange={onChangeHandler}
                                value={state.tag}
                                name="tag"
                                select
                                disabled={true}
                            >
                                {tags.map((tag) => (
                                    <MenuItem key={tag.id} value={tag.id} style={{ color: tag.color }}>{tag.name}</MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    </Grid>
                    <br />
                    <InputLabel>Days of week</InputLabel>
                    <Grid
                        container
                        direction="row"
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    id="monday"
                                    label="monday"
                                    color="primary"
                                    onChange={daysCheckboxHandler}
                                    value={state.days[0]}
                                    name="0"
                                    checked={state.days[0]}
                                />
                            }
                            label="Mon"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    id="tuesday"
                                    label="tuesday"
                                    color="primary"
                                    onChange={daysCheckboxHandler}
                                    value={state.days[1]}
                                    name="1"
                                    checked={state.days[1]}
                                />
                            }
                            label="Tue"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    id="wednesday"
                                    label="wednesday"
                                    color="primary"
                                    onChange={daysCheckboxHandler}
                                    value={state.days[2]}
                                    name="2"
                                    checked={state.days[2]}
                                />
                            }
                            label="Wed"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    id="thursday"
                                    label="thursday"
                                    color="primary"
                                    onChange={daysCheckboxHandler}
                                    value={state.days[3]}
                                    name="3"
                                    checked={state.days[3]}
                                />
                            }
                            label="Thu"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    id="friday"
                                    label="friday"
                                    color="primary"
                                    onChange={daysCheckboxHandler}
                                    value={state.days[4]}
                                    name="4"
                                    checked={state.days[4]}
                                />
                            }
                            label="Fri"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    id="saturday"
                                    label="saturday"
                                    color="primary"
                                    onChange={daysCheckboxHandler}
                                    value={state.days[5]}
                                    name="5"
                                    checked={state.days[5]}
                                />
                            }
                            label="Sat"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    id="sunday"
                                    label="sunday"
                                    color="primary"
                                    onChange={daysCheckboxHandler}
                                    value={state.days[6]}
                                    name="6"
                                    checked={state.days[6]}
                                />
                            }
                            label="Sun"
                        />
                    </Grid>
                    <br />
                    <InputLabel>Other Options</InputLabel>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                    >
                        <Grid item xs>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id="parted"
                                        label="Parted"
                                        color="primary"
                                        onChange={checkboxHandler}
                                        value={state.parted}
                                        name="parted"
                                        checked={state.parted}
                                        disabled
                                    />
                                }
                                label="Parted"
                            />
                        </Grid>
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        disabled={Object.values(errors).includes(true)}
                        id="smart-task-submit-button"
                    >
                        Add Smart Task
                    </Button>

                </form>
            </div>
        </Container>
    )

}