import { API } from '../../globals';
import { fetchTasks } from './calendar'

export const ACCOUNT_DETAILS_FETCHING = "[accountDetails] fetching";
export const ACCOUNT_DETAILS_FETCHED = "[accountDetails] fetched";
export const ACCOUNT_DETAILS_ERROR = "[accountDetails] error";

export const accountDetailsFetching = () => ({
    type: ACCOUNT_DETAILS_FETCHING,
});
export const accountDetailsFetched = (resp) => ({
    type: ACCOUNT_DETAILS_FETCHED,
    payload: resp,
});
export const accountDetailsError = (resp) => ({
    type: ACCOUNT_DETAILS_ERROR,
    payload: resp,
})

export const fetchAccountDetails = () => (dispatch, getState) => {
    dispatch(accountDetailsFetching())
    const { token } = getState().auth;
    let headers = { "Content-Type": "application/json" }
    if (token) {
        headers["Authorization"] = `JWT ${token}`
    }
    fetch(`${API}/account_details/`, { headers, })
        .then(resp => resp.json())
        .then(resp => {
            dispatch(accountDetailsFetched(resp))
        })
        .catch(resp => dispatch(accountDetailsError(resp)))
}

export const patchAnnualColor = (data) => (dispatch, getState) => {
    dispatch(accountDetailsFetching())
    const { token } = getState().auth;
    let headers = { "Content-Type": "application/json" }
    if (token) {
        headers["Authorization"] = `JWT ${token}`
    }

    const serializedData = {
        annual_tag_color : data.color 
    }

    const packToSend = {
        method: "PATCH",
        headers: headers,
        body: JSON.stringify({ ...serializedData })
    }
    fetch(`${API}/account_details/`, packToSend)
        .then(resp => resp.json())
        .then(dispatch(fetchAccountDetails()))
        .then(dispatch(fetchTasks(new Date())))
        .catch(resp => dispatch(accountDetailsError(resp)))
}