import React, {useEffect, useState} from 'react';
import {Calendar, momentLocalizer} from "react-big-calendar";
import {Button} from '@material-ui/core'
import moment from "moment";
import 'moment/locale/en-gb';
import CalendarToolbar from "../containers/CalendarToolbar";
import DayModal from "./DayModal";
import TaskModal from "./TaskModal";
import ErrorModal from './ErrorModal';
import Drawer from '../containers/Drawer'
import DateCellWrapper from "./DateCellWrapper";
import './css/Calendar.css';

export default ({
                    tasks,
                    fetchTasks,
                    fetchWorkHours,
                    fetchPrivateHours,
                    fetchTags,
                    error,
                    taskError,
                    tagsToDisplay,
                    fetchAccountDetails,
                }) => {

    const [calDate, setCalDate] = useState(new Date())
    const [modalOpen, setModalOpen] = useState(false)
    const [errorModalOpen, setErrorModalOpen] = useState(false)
    const [modalDate, setModalDate] = useState(null)
    const [modalTasks, setModalTasks] = useState([])
    const [taskModalOpen, setTaskModalOpen] = useState(false)
    const [taskId, setTaskId] = useState("")
    const [taskType, setTaskType] = useState("")
    const [drawerOpen, setDrawerOpen] = useState(false)
    const [tasksToShow, setTasksToShow] = useState([])

    useEffect(() => {
        fetchTasks(calDate);
        fetchWorkHours();
        fetchPrivateHours();
        fetchTags();
        fetchAccountDetails();
    }, [fetchTasks, fetchWorkHours, fetchPrivateHours, fetchTags, calDate, fetchAccountDetails,])

    useEffect(() => {
        if (error !== "") {
            setErrorModalOpen(true)
            taskError()
        }
    }, [error, taskError])

    useEffect(() => {
        const tasksCopy = [...tasks]
        setTasksToShow(tasksCopy.filter((task) => (tagsToDisplay[task.tag.name])))
    }, [tagsToDisplay, tasks])

    const taskTitle = (task) => {
        if (task.type === "task") {
            return (`${task.title} ${task.startTime} - ${task.endTime}`)
        } else {
            return (`${task.title}`)
        }
    }

    const errorModalCloseHandler = () => {
        setErrorModalOpen(false)
    }

    const onNavigateHandler = (date) => {
        setCalDate(date)
    }

    const onSelectHandler = (event) => {
        const makeDate = (dayDate) => {
            const dateDay = (dayDate) => {
                if (dayDate.getDate().toString().length === 1) {
                    return `0${dayDate.getDate()}`
                } else {
                    return dayDate.getDate()
                }
            }
            const dateMonth = (dayDate) => {
                if ((dayDate.getMonth() + 1).toString().length === 1) {
                    return `0${dayDate.getMonth() + 1}`
                } else {
                    return `${dayDate.getMonth() + 1}`
                }
            }
            const dateToReturn = `${dayDate.getFullYear()}-${dateMonth(dayDate)}-${dateDay(dayDate)}`
            return dateToReturn
        };
        const dayDate = makeDate(event.slots[0])
        const filterTasks = (task) => (task.taskDate === dayDate)
        setModalDate(dayDate)
        const filteredTasks = tasks.filter(filterTasks).sort((taskA, taskB) => (taskA.startTime < taskB.startTime) ? 1 : -1).sort((taskA, taskB) => (taskA.type > taskB.type) ? 1 : -1)
        setModalTasks(filteredTasks)
        if (filteredTasks.length > 0) {
            setModalOpen(true);
        }
    };

    const onSelectEventHandler = (event) => {
        setTaskId(event.taskId);
        setTaskType(event.type)
        setTaskModalOpen(true);
    }

    const closeModalHandler = () => {
        setModalOpen(false);
    };

    const closeTaskModalHandler = () => {
        setTaskModalOpen(false);
    };

    const openDrawerHandler = () => {
        setDrawerOpen(true)
    };

    const closeDrawerHandler = () => {
        setDrawerOpen(false)
    };

    const localizer = momentLocalizer(moment)

    const styleDrawerOpen = {
        height: "100vh",
        width: "90vw",
        float: "right",

    }

    const styleDrawerClosed = {
        height: "100vh",
        width: "100vw",
        float: "right"
    }


    moment.locale('en-GB');
    momentLocalizer(moment);

    return (
        <div style={drawerOpen ? styleDrawerOpen : styleDrawerClosed}>
            <Calendar
                style={{
                    ...Calendar.style,

                }}
                events={tasksToShow}
                localizer={localizer}
                eventPropGetter={event => ({
                    style: {
                        backgroundColor: event.tag.color,
                        padding: "1px 1px",
                        // margin: "1px 2px",
                    }
                })}
                startAccessor="start"
                endAccessor="end"
                titleAccessor={taskTitle}
                views={["month", "week",]}
                date={calDate}
                culture="en-GB"
                onNavigate={onNavigateHandler}
                onSelectEvent={onSelectEventHandler}
                onSelectSlot={onSelectHandler}
                onDrillDown={(event) => onSelectHandler({slots: [event]})}
                popup={true}
                selectable={true}
                messages={{
                    showMore: (total) => (<Button variant='contained' fullWidth
                                                  style={{padding: '1px 1px', textDecoration: 'none'}}
                                                  size='small'>+{total} more</Button>)
                }}
                components={{
                    toolbar: (props) => <CalendarToolbar {...props} openDrawerHandler={openDrawerHandler}/>,
                    dateCellWrapper: DateCellWrapper,
                }}
            />
            <DayModal open={modalOpen} closeHandler={closeModalHandler} date={modalDate} tasks={modalTasks}/>
            <TaskModal open={taskModalOpen} closeHandler={closeTaskModalHandler} taskId={taskId} taskType={taskType}/>
            <ErrorModal open={errorModalOpen} closeHandler={errorModalCloseHandler}/>
            <Drawer isOpen={drawerOpen} closeHandler={closeDrawerHandler}/>
        </div>
    )
}