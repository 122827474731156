import { makeStyles } from "@material-ui/core"

export default () => {
    const useStyles = makeStyles((theme) => ({
        paper: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
        },
        form: {
            width: "100%",
            marginTop: theme.spacing(1),
        },
        submit: {
            margin: theme.spacing(3, 0, 2)
        },
        inlineInput : {
            display : "inline-flex",
            marginLeft : "auto",
            marginRight : "auto",
            width: "20vh"
        }
    }))


    return useStyles()
}