import { TAGS_ERROR, TAGS_FETCHED, TAGS_FETCHING, TAGS_DISPLAY, WORK_HOURS_ERROR, WORK_HOURS_FETCHED, WORK_HOURS_FETCHING, PRIVATE_HOURS_ERROR, PRIVATE_HOURS_FETCHED, PRIVATE_HOURS_FETCHING } from "../actions/profile";

const initState = {
    loading: false,
    tags: [],
    tagsError: "",
    tagsToDisplay: {},
    workHours: {},
    workHoursError: "",
    privateHours: {},
    privateHoursError: "",
};

export default (state = initState, action) => {
    switch (action.type) {
        case WORK_HOURS_ERROR:
            return { ...state, loading: false, workHoursError: action.payload };
        case WORK_HOURS_FETCHING:
            return { ...state, loading: true };
        case WORK_HOURS_FETCHED:
            return { ...state, loading: false, workHours: action.payload };
        case PRIVATE_HOURS_ERROR:
            return { ...state, loading: false, privateHoursError: action.payload};
        case PRIVATE_HOURS_FETCHING:
            return { ...state, loading: true };
        case PRIVATE_HOURS_FETCHED:
            return { ...state, loading: false, privateHours: action.payload }
        case TAGS_ERROR:
            return { ...state, loading: false, tagsError: action.payload };
        case TAGS_FETCHING:
            return { ...state, loading: true };
        case TAGS_FETCHED:
            const tagsCopy = [...action.payload]
            let tagsToDisplay = { Annual: true }
            tagsCopy.forEach((value) => {
                tagsToDisplay[value.name] = true
            })
            return { ...state, loading: false, tags: action.payload, tagsToDisplay: tagsToDisplay }
        case TAGS_DISPLAY:
            const name = action.payload[0]
            const checked = action.payload[1]
            return {
                ...state, tagsToDisplay: {
                    ...state.tagsToDisplay,
                    [name]: checked
                }
            }
        default:
            return state;
    }
}