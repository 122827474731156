import { makeStyles } from "@material-ui/core"

export default () => {
    const useStyles = makeStyles((theme) => ({
        menuButton: {
            marginRight: theme.spacing(3),
        },
        picker:{
            width: "8vw",
        },
        toolbarRight: {
            marginLeft: 'auto',
            '& button': {
                marginLeft: theme.spacing(2),
            },
        },
        toolbarLeft: {
            marginRight: 'auto',
        },
    }))

    return useStyles()
}