import { API } from "../../globals";
import { fetchTasks } from './calendar'

export const WORK_HOURS_FETCHING = "[workHours] Work Hours Fetching";
export const WORK_HOURS_FETCHED = "[workHours] Work Hours Fetched";
export const WORK_HOURS_ERROR = "[workHours] Work Hours Error";
export const PRIVATE_HOURS_FETCHING = "[privateHours] Private Hours Fetching";
export const PRIVATE_HOURS_FETCHED = "[privateHours] Private Hours Fetched";
export const PRIVATE_HOURS_ERROR = "[privateHours] Private Hours Error";
export const TAGS_FETCHING = "[profile] Tags Fetching";
export const TAGS_FETCHED = "[profile] Tags Fetched";
export const TAGS_ERROR = "[profile] Tags Error";
export const TAGS_DISPLAY = "[profile] Tags Display";


export const workHoursFetching = () => ({
    type: WORK_HOURS_FETCHING,
});

export const workHoursFetched = (resp) => ({
    type: WORK_HOURS_FETCHED,
    payload: resp,
});

export const workHoursError = (resp) => ({
    type: WORK_HOURS_ERROR,
    payload: resp,
});

export const privateHoursFetching = () => ({
    type: PRIVATE_HOURS_FETCHING,
})

export const privateHoursFetched = (resp) => ({
    type: PRIVATE_HOURS_FETCHED,
    payload: resp,
})

export const privateHoursError = (resp) => ({
    type: PRIVATE_HOURS_ERROR,
    payload: resp,
})

export const tagsFetching = () => ({
    type: TAGS_FETCHING
});

export const tagsFetched = (resp) => ({
    type: TAGS_FETCHED,
    payload: resp,
});

export const tagsError = (resp) => ({
    type: TAGS_ERROR,
    payload: resp,
})

export const tagsDisplay = (name, checked) => ({
    type: TAGS_DISPLAY,
    payload: [name, checked]
})

export const fetchWorkHours = () => (dispatch, getState) => {
    dispatch(workHoursFetching());
    const { token } = getState().auth;
    let headers = { "Content-Type": "application/json" }
    if (token) {
        headers["Authorization"] = `JWT ${token}`
    }
    fetch(`${API}/work_hours/`, { headers, })
        .then(resp => resp.json())
        .then(resp => {
            dispatch(workHoursFetched(resp))
        })
        .catch(resp => {
            dispatch(workHoursError(resp))
        })
};

export const changeWorkHours = (data) => (dispatch, getState) => {
    dispatch(workHoursFetching());
    const { token } = getState().auth;
    let headers = { "Content-Type": "application/json" };
    if (token) {
        headers["Authorization"] = `JWT ${token}`
    };

    const packToSend = {
        method: "PATCH",
        headers: headers,
        body: JSON.stringify({ ...data })
    };

    fetch(`${API}/work_hours/`, packToSend)
        .then(resp => resp.json())
        .then(dispatch(fetchWorkHours()))
        .catch(resp => dispatch(workHoursError(resp)))
}

export const fetchPrivateHours = () => (dispatch, getState) => {
    dispatch(privateHoursFetching());
    const { token } = getState().auth;
    let headers = { "Content-Type": "application/json" }
    if (token) {
        headers["Authorization"] = `JWT ${token}`
    }
    fetch(`${API}/private_hours/`, { headers, })
        .then(resp => resp.json())
        .then(resp => {
            dispatch(privateHoursFetched(resp))
        })
        .catch(resp => {
            dispatch(privateHoursError(resp))
        })
};

export const changePrivateHours = (data) => (dispatch, getState) => {
    dispatch(privateHoursFetching());
    const { token } = getState().auth;
    let headers = { "Content-Type": "application/json" };
    if (token) {
        headers["Authorization"] = `JWT ${token}`
    };

    const packToSend = {
        method: "PATCH",
        headers: headers,
        body: JSON.stringify({ ...data })
    };

    fetch(`${API}/private_hours/`, packToSend)
        .then(resp => resp.json())
        .then(dispatch(fetchPrivateHours()))
        .catch(resp => dispatch(privateHoursError(resp)))
}


export const fetchTags = () => (dispatch, getState) => {
    dispatch(tagsFetching());
    const { token } = getState().auth;
    let headers = { "Content-Type": "application/json" }
    if (token) {
        headers["Authorization"] = `JWT ${token}`
    }
    fetch(`${API}/tags`, { headers, })
        .then(resp => resp.json())
        .then(resp => {
            dispatch(tagsFetched(resp))
        })
        .catch(resp => {
            dispatch(tagsError(resp))
        })
}

export const addNewTag = (data) => (dispatch, getState) => {
    dispatch(tagsFetching());
    const { token } = getState().auth;
    let headers = { "Content-Type": "application/json" }
    if (token) {
        headers["Authorization"] = `JWT ${token}`
    }
    const packToSend = {
        method: "POST",
        headers: headers,
        body: JSON.stringify({ ...data })
    };
    fetch(`${API}/tags/`, packToSend)
        .then(resp => resp.json())
        .then(dispatch(fetchTags()))
        .catch(resp => dispatch(tagsError(resp)))
}

export const updateTag = (data) => (dispatch, getState) => {
    dispatch(tagsFetching());
    const { token } = getState().auth;
    let headers = { "Content-Type": "application/json" }
    if (token) {
        headers["Authorization"] = `JWT ${token}`
    }

    const packToSend = {
        method: "PATCH",
        headers: headers,
        body: JSON.stringify({ ...data })
    }

    fetch(`${API}/tags/${data.id}/`, packToSend)
        .then(resp => resp.json())
        .then(dispatch(fetchTags()))
        .then(dispatch(fetchTasks(new Date())))
        .catch(resp => dispatch(tagsError(resp)))
}

export const setDisplayTag = (name, checked) => (dispatch) => {
    dispatch(tagsDisplay(name, checked))
}