import { connect } from "react-redux";
import ProfileSettingsHours from "../components/ProfileSettingsHours";
import { changeWorkHours } from "../redux/actions/profile";

const mapState = (state, ownProps) => ({
    hoursData: state.profile.workHours,
    ownProps,
});

const mapDispatch = (dispatch) => ({
    APIFunction: (data) => dispatch(changeWorkHours(data))
});

export default connect(mapState,mapDispatch)(ProfileSettingsHours);