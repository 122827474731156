import React, { useState } from 'react';
import { Button, CssBaseline, TextField, Grid, Container, } from "@material-ui/core";
import useStyles from "./styles/FormStyles";

export default ({ hoursData, closeHandler, APIFunction }) => {

    const [state, setState] = useState(hoursData);

    const onSubmitHandler = event => {
        event.preventDefault();
        APIFunction(state);
        closeHandler();
    }

    const onChangeHandler = ({ target: { name, value } }) => {
        setState(prevState => ({
            ...prevState,
            [name]: value
        }))
    }

    const classes = useStyles();

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <form className={classes.form} onSubmit={onSubmitHandler}>
                    <Grid item xs={12}>
                        <Grid item xs={6} className={classes.inlineInput}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="monday_from"
                                label="Monday Start"
                                onChange={onChangeHandler}
                                value={state.monday_from}
                                name="monday_from"
                                type="time"
                            />
                        </Grid>
                        <Grid item xs={6} className={classes.inlineInput}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="monday_to"
                                label="Monday End"
                                onChange={onChangeHandler}
                                value={state.monday_to}
                                name="monday_to"
                                type="time"
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid item xs={6} className={classes.inlineInput}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="tuesday_from"
                                label="Tuesday start"
                                onChange={onChangeHandler}
                                value={state.tuesday_from}
                                name="tuesday_from"
                                type="time"
                            />
                        </Grid>
                        <Grid item xs={6} className={classes.inlineInput}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="tuesday_to"
                                label="Tuesday End"
                                onChange={onChangeHandler}
                                value={state.tuesday_to}
                                name="tuesday_to"
                                type="time"
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid item xs={6} className={classes.inlineInput}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="wednesday_from"
                                label="Wednesday start"
                                onChange={onChangeHandler}
                                value={state.wednesday_from}
                                name="wednesday_from"
                                type="time"
                            />
                        </Grid>
                        <Grid item xs={6} className={classes.inlineInput}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="wednesday_to"
                                label="Wednesday end"
                                onChange={onChangeHandler}
                                value={state.wednesday_to}
                                name="wednesday_to"
                                type="time"
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid item xs={6} className={classes.inlineInput}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="thursday_from"
                                label="Thursday start"
                                onChange={onChangeHandler}
                                value={state.thursday_from}
                                name="thursday_from"
                                type="time"
                            />
                        </Grid>
                        <Grid item xs={6} className={classes.inlineInput}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="thursday_to"
                                label="Thursday end"
                                onChange={onChangeHandler}
                                value={state.thursday_to}
                                name="thursday_to"
                                type="time"
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid item xs={6} className={classes.inlineInput}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="friday_from"
                                label="Friday start"
                                onChange={onChangeHandler}
                                value={state.friday_from}
                                name="friday_from"
                                type="time"
                            />
                        </Grid>
                        <Grid item xs={6} className={classes.inlineInput}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="friday_to"
                                label="Friday end"
                                onChange={onChangeHandler}
                                value={state.friday_to}
                                name="friday_to"
                                type="time"
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid item xs={6} className={classes.inlineInput}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="saturday_from"
                                label="Saturday start"
                                onChange={onChangeHandler}
                                value={state.saturday_from}
                                name="saturday_from"
                                type="time"
                            />
                        </Grid>
                        <Grid item xs={6} className={classes.inlineInput}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="saturday_to"
                                label="Saturday end"
                                onChange={onChangeHandler}
                                value={state.saturday_to}
                                name="saturday_to"
                                type="time"
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid item xs={6} className={classes.inlineInput}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="sunday_from"
                                label="Sunday start"
                                onChange={onChangeHandler}
                                value={state.sunday_from}
                                name="sunday_from"
                                type="time"
                            />
                        </Grid>
                        <Grid item xs={6} className={classes.inlineInput}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="sunday_to"
                                label="Sunday end"
                                onChange={onChangeHandler}
                                value={state.sunday_to}
                                name="sunday_to"
                                type="time"
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            id="profile-hours-submit-button"
                        >
                            Submit
                        </Button>
                    </Grid>
                </form>
            </div>
        </Container>
    );


};