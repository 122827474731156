import NormalTaskForm from "../components/AddNormalTaskForm"
import { connect } from "react-redux"
import { addTaskToAPI } from "../redux/actions/calendar"

const mapState = (state, ownProps) => ({
    tags: state.profile.tags,
    ownProps
})

const mapDispatch = (dispatch) => ({
    addTaskToAPI: (data) => (dispatch(addTaskToAPI(data)))
})

export default connect(mapState, mapDispatch)(NormalTaskForm)