import React, { useState } from 'react'
import { Button, CssBaseline, TextField, Grid, Box, Container, Typography, Link } from "@material-ui/core"
import Copyright from "./Copyright"
import useStyles from "./styles/FormStyles"
import FBSocialAuth from "../containers/FBSocialAuth"
import GoogleSocialAuth from "../containers/GoogleSocialAuth"

export default ({ loginHandler, ...props }) => {

    const [state, setState] = useState({ username: "", password: "" })

    const onChangeHandler = ({ target: { name, value } }) => {
        setState(prevState => ({
            ...prevState,
            [name]: value
        }))
    }

    const onSubmitHandler = event => {
        event.preventDefault()
        loginHandler(state)
    }


    const classes = useStyles()

    return (
        <div>
            <h1 style={{margin: "2%"}}>LOGO</h1>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    <Typography component="h1" variant="h5">
                        Sign In
                </Typography>
                    <form className={classes.form} onSubmit={onSubmitHandler}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="username"
                            label="Username"
                            onChange={onChangeHandler}
                            value={state.username}
                            name="username"
                            autoComplete="username"
                            autoFocus
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            onChange={onChangeHandler}
                            value={state.password}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            id="login-submit-button"
                        >
                            Sign In!
                    </Button>
                    {/* <FBSocialAuth />
                    <GoogleSocialAuth />
                        <Grid container>
                            <Grid item xs>
                                <Link href="/sign-up" variant="body2">
                                    {"Don't have an account? Sign Up"}
                                </Link>
                            </Grid>
                        </Grid> */}
                    </form>
                </div>
                <Box mt={8}>
                    <Copyright />
                </Box>
            </Container>
        </div>
    )

}