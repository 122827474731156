import React, { useState } from 'react'
import useStyles from "./styles/FormStyles"
import { Button, Container, CssBaseline, Grid, TextField, MenuItem, FormControlLabel, Checkbox } from "@material-ui/core"
import { normalTaskSerializer } from "./utils/TaskFormUtils"

export default ({ addTaskToAPI, closeHandler, tags }) => {

    const [state, setState] = useState({
        name: "",
        description: "",
        date: "",
        taskStart: "00:00",
        taskEnd: "00:00",
        tag: tags[0].id,
        force_override: false,
        task_type: tags[0].name.toLowerCase(),
    })

    const [errors, setErrors] = useState({
        name: false,
        description: false,
        date: false,
        taskStart: false,
        taskEnd: false,
    })

    const onSubmitHandler = (event) => {
        event.preventDefault()
        const serializedState = normalTaskSerializer(state)
        addTaskToAPI(serializedState)
        closeHandler()
    }

    const checkboxHandler = ({ target: { name, checked } }) => {
        setState(prevState => ({
            ...prevState,
            [name]: checked
        }))
    }

    const onTagChangeHandler = ({target : {name, value}}) => {
        const tagName = tags.filter(tag => tag.id === value)[0].name.toLowerCase()
        setState(prevState => ({
            ...prevState,
            [name]: value,
            task_type: tagName,
        }))
    }

    const onChangeHandler = ({ target: { name, value } }) => {
        setState(prevState => ({
            ...prevState,
            [name]: value
        }))
        const nameAndDescriptionRegEx = RegExp(/[a-zA-Z,.?!@"'()/-:+\n \p{L}]/mgu)
        switch (name) {
            case "name":
            case "description":
                if (value !== "") {
                    if (value.match(nameAndDescriptionRegEx) === null || value.match(nameAndDescriptionRegEx).length !== value.length) {
                        setErrors({
                            ...errors,
                            [name]: true
                        })
                    } else {
                        setErrors({
                            ...errors,
                            [name]: false
                        })
                    }
                }
                break;

            case "date":
                if (value < new Date().toISOString().slice(0, 10)) {
                    setErrors({
                        ...errors,
                        [name]: true
                    })
                } else {
                    setErrors({
                        ...errors,
                        [name]: false
                    })
                }
                break;

            case "taskEnd":
                if (value < state.taskStart) {
                    setErrors({
                        ...errors,
                        [name]: true
                    })
                } else {
                    setErrors({
                        ...errors,
                        [name]: false
                    })
                }
                break;
            default:
                break;
        }
    }

    const classes = useStyles()

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <form className={classes.form} onSubmit={onSubmitHandler}>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            autoFocus
                            onChange={onChangeHandler}
                            value={state.name}
                            error={errors.name}
                            helperText={errors.name ? "Incorrect values" : ""}
                            id="name"
                            name="name"
                            label="Task name"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            onChange={onChangeHandler}
                            value={state.date}
                            error={errors.date}
                            helperText={errors.date ? "Incorrect date" : ""}
                            id="date"
                            name="date"
                            label="Date"
                            type="date"
                            InputLabelProps={{
                                shrink: true
                            }}
                            style={{ width: "40vh" }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            onChange={onChangeHandler}
                            value={state.description}
                            error={errors.description}
                            helperText={errors.description ? "Incorrect value" : ""}
                            id="description"
                            name="description"
                            label="Description"
                            multiline
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid item xs={6} className={classes.inlineInput}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                required
                                onChange={onChangeHandler}
                                value={state.taskStart}
                                id="task-start"
                                name="taskStart"
                                label="Task start"
                                type="time"

                            />
                        </Grid>
                        <Grid item xs={6} className={classes.inlineInput}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                required
                                onChange={onChangeHandler}
                                value={state.taskEnd}
                                error={errors.taskEnd}
                                helperText={errors.taskEnd ? "Incorrect value" : ""}
                                id="task-end"
                                name="taskEnd"
                                label="Task end"
                                type="time"

                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            required
                            fullWidth
                            id="tag"
                            label="Tag"
                            onChange={onTagChangeHandler}
                            value={state.tag}
                            name="tag"
                            select
                        >
                            {tags.map((tag) => (
                                <MenuItem key={tag.id} value={tag.id} style={{ color: tag.color }}>{tag.name}</MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    id="force_overrride"
                                    label="Force override"
                                    color="primary"
                                    onChange={checkboxHandler}
                                    value={state.force_override}
                                    name="force_override"
                                    checked={state.force_override}
                                />
                            }
                            label="Force override"
                        />
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        disabled={Object.values(errors).includes(true)}
                        id="normal-task-submit-button"
                    >
                        Add normal task
                        </Button>
                </form>
            </div>
        </Container>
    )

};