import { API } from "../../globals"

export const USER_LOADING = "[auth] user loading";
export const USER_LOADED = "[auth] user loaded";
export const LOGIN_SUCCESSFUL = "[auth] login successful";
export const AUTHENTICATION_ERROR = "[auth] authentication error";
export const LOGIN_FAILED = "[auth] login failed";
export const LOGOUT_SUCCESSFUL = "[auth] logout successful";

export const userLoading = () => ({
    type: USER_LOADING
});
export const userLoaded = (resp) => ({
    type: USER_LOADED,
    payload: resp
});
export const loginSuccessful = (resp) => ({
    type: LOGIN_SUCCESSFUL,
    payload: resp
});
export const authenticationError = (resp) => ({
    type: AUTHENTICATION_ERROR,
    payload: resp
});
export const loginFailed = (resp) => ({
    type: LOGIN_FAILED,
    payload: resp
});
export const logoutSuccessful = () => ({
    type: LOGOUT_SUCCESSFUL,
});


export const loginHandler = (data) => (dispatch) => {
    dispatch(userLoading());
    fetch(`${API}/token-auth/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    })
        .then(resp => resp.json())
        .then(resp => {
            localStorage.setItem('token', resp.token)
            dispatch(loginSuccessful(resp))
        })
        .catch(resp => {
            localStorage.removeItem('token')
            dispatch(loginFailed(resp))
        })
};

export const facebookLoginHandler = (data) => (dispatch) => {
    dispatch(userLoading());
    fetch(`${API}/rest-auth/facebook`,{
        method : 'POST',
        headers: {
            'Content-Type' : 'application/json'
        },
        body: JSON.stringify({"access_token" : `${data}`})
    })
    .then(resp => resp.json())
    .then(resp => {
        localStorage.setItem('token', resp.token)
        dispatch(loginSuccessful(resp))
    })
    .catch(resp => {
        localStorage.setItem('token', resp.token)
        dispatch(loginFailed(resp))
    })
}

export const googleLoginHandler = (data) => (dispatch) => {
    dispatch(userLoading());
    fetch(`${API}/rest-auth/google`,{
        method: 'POST',
        headers: {
            'Content-Type' : 'application/json'
        },
        body: JSON.stringify({"access_token" : `${data}`})
    })
    .then(resp => resp.json())
    .then(resp => {
        localStorage.setItem('token', resp.token)
        dispatch(loginSuccessful(resp))
    })
    .catch(resp => {
        localStorage.setItem('token', resp.token)
        dispatch(loginFailed(resp))
    })
}

export const signupHandler = (data) => (dispatch) => {
    dispatch(userLoading());
    fetch(`${API}/users/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    })
        .then(resp => resp.json())
        .then(resp => {
            localStorage.setItem('token', resp.token)
            dispatch(loginHandler(resp))
        })
        .catch(resp => {
            localStorage.removeItem('token')
            dispatch(loginFailed(resp))
        })
};

export const logoutHandler = () => (dispatch) => {
    localStorage.removeItem('token')
    dispatch(logoutSuccessful())
};

export const loadUserHandler = (token) => (dispatch) => {
    dispatch(userLoading())
    fetch(`${API}/current_user`, {
        headers: {
            Authorization: `JWT ${token}`
        }
    })
        .then(resp => resp.json())
        .then(resp => {
            dispatch(userLoaded(resp.username))
        })
}
