import { API } from "../../globals";
import { serializeData, selectedDays, serializeAnnuals, serializeSingleAnnual } from "./utils";
import moment from 'moment';

export const TASK_ADD = "[calendar] Add task"
export const TASK_FETCHING = "[calendar] Fetching task"
export const TASK_FETCHED = "[calendar] Task fetched"
export const TASK_ERROR = "[calendar] Task fetching error"

export const addTask = (resp) => ({
    type: TASK_ADD,
    payload: resp
})

export const taskFetching = () => ({
    type: TASK_FETCHING
})

export const taskFetched = (resp) => ({
    type: TASK_FETCHED,
    payload: resp
})

export const taskError = (resp) => ({
    type: TASK_ERROR,
    payload: resp
})

export const fetchTasks = (date) => (dispatch, getState) => {
    dispatch(taskFetching())
    const { token } = getState().auth;
    let headers = { "Content-Type": "application/json" }
    if (token) {
        headers["Authorization"] = `JWT ${token}`
    }

    fetch(`${API}/parts/${date.getFullYear()}/${date.getMonth() + 1}`, { headers, })
        .then(resp => resp.json())
        .then(resp => {
            const data = serializeData(resp)
            dispatch(taskFetched(data))
        })
        .then(resp => fetch(`${API}/annuals/${date.getMonth() + 1}`, { headers, })
            .then(resp => resp.json())
            .then(resp => {
                const data = serializeAnnuals(resp, date)
                dispatch(addTask(data))
            }))

}

export const addTaskToAPI = (data) => (dispatch, getState) => {
    dispatch(taskFetching)
    const { token } = getState().auth;
    let headers = { "Content-Type": "application/json" }
    if (token) {
        headers["Authorization"] = `JWT ${token}`
    }
    const serializedData = {
        ...data,
        duration: `${data.duration}:00`,
        max_part_length: `${data.max_part_length}:00`,
        days: selectedDays(data.days),
        local_datetime: new Date().toLocaleString(),
    }

    const packToSend = {
        method: "POST",
        headers: headers,
        body: JSON.stringify({ ...serializedData })
    }


    fetch(`${API}/tasks/`, packToSend)
        .then(resp => {
            if (!resp.ok) {
                let taskError = new Error("HTTP status code: " + resp.status)
                taskError.response = resp
                taskError.status = resp.status
                throw taskError
            }
            return resp
        })
        .then(resp => resp.json())
        .then(task => {
            if (task.priority === 4) {
                dispatch(fetchTasks(moment(`${task.date_from} ${task.taskparts[0].start}`).toDate()))
            } else {
                dispatch(addTask(serializeData(task.taskparts)))
            }
        })
        .catch(resp => dispatch(taskError(resp)))
}

export const deleteTaskFromAPI = (taskId) => (dispatch, getState) => {
    dispatch(taskFetching)
    const { token } = getState().auth;
    let headers = { "Content-Type": "application/json" }
    if (token) {
        headers["Authorization"] = `JWT ${token}`
    }
    const packToSend = {
        method: "DELETE",
        headers: headers,
    }

    fetch(`${API}/tasks/${taskId}/`, packToSend)
        .then(dispatch(fetchTasks(new Date())))
        .catch(resp => dispatch(taskError(resp)))
}

export const deleteAnnualFromAPI = (taskId) => (dispatch, getState) => {
    dispatch(taskFetching)
    const { token } = getState().auth;
    let headers = { "Content-Type": "application/json" }
    if (token) {
        headers["Authorization"] = `JWT ${token}`
    }
    const packToSend = {
        method: "DELETE",
        headers: headers,
    }

    fetch(`${API}/annuals/view/${taskId}/`, packToSend)
        .then(dispatch(fetchTasks(new Date())))
        .catch(resp => dispatch(taskError(resp)))
}

export const putSmartTask = (data) => (dispatch, getState) => {
    dispatch(taskFetching)
    const { token } = getState().auth;
    let headers = { "Content-Type": "application/json" }
    if (token) {
        headers["Authorization"] = `JWT ${token}`
    }
    const serializedData = {
        ...data[0],
        duration: `${data[0].duration}:00`,
        max_part_length: `${data[0].max_part_length}:00`,
        days: selectedDays(data[0].days)
    }

    const packToSend = {
        method: "PUT",
        headers: headers,
        body: JSON.stringify({ ...serializedData })
    }


    fetch(`${API}/tasks/${data[1]}/`, packToSend)
        .then(resp => {
            if (!resp.ok) {
                let taskError = new Error("HTTP status code: " + resp.status)
                taskError.response = resp
                taskError.status = resp.status
                throw taskError
            }
            return resp
        })
        .then(task => {
            dispatch(fetchTasks(new Date()))
        })
        .catch(resp => dispatch(taskError(resp)))
}

export const addAnnualToAPI = (data) => (dispatch, getState) => {
    dispatch(taskFetching)
    const { token } = getState().auth;
    let headers = { "Content-Type": "application/json" }
    if (token) {
        headers["Authorization"] = `JWT ${token}`
    }

    const packToSend = {
        method: "POST",
        headers: headers,
        body: JSON.stringify({ ...data })
    }

    fetch(`${API}/annuals/`, packToSend)
        .then(resp => resp.json())
        .then(resp => {
            const data = serializeSingleAnnual(resp, new Date())
            dispatch(addTask([data]))
        })
        .catch(resp => dispatch(taskError(resp)))
}