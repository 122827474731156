import CalendarToolbar from "../components/CalendarToolbar"
import { fetchTasks } from "../redux/actions/calendar"
import { logoutHandler } from "../redux/actions/auth"
import { connect } from "react-redux"

const mapDispatch = (dispatch) => ({
    fetchTasks : (calDate) => dispatch(fetchTasks(calDate)),
    logoutHandler : () => dispatch(logoutHandler())
})

export default connect(null,mapDispatch)(CalendarToolbar)