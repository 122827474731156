import React, { useState } from 'react';
import { Modal, Fade, Backdrop, MenuItem } from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab"
import useStyles from "./styles/ModalStyles";
import ProfileSettingsWorkHours from "../containers/ProfileSettingsWorkHours";
import ProfileSettingsPrivateHours from "../containers/ProfileSettingsPrivateHours";
import UserTagsList from "../containers/UserTagsList";
import PasswordChangeForm from './PasswordChangeForm'

export default ({ socialAccount, menuCloseHandler }) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [currentForm, setCurrentForm] = useState("profileHours");

    const handleOpen = () => {
        menuCloseHandler();
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const aligmentHandler = (event, newAligment) => {
        if (newAligment !== null) {
            setCurrentForm(newAligment);
        };
    };

    const renderForm = (currentForm) => {
        switch (currentForm) {
            case "profileHoursWork":
                return <ProfileSettingsWorkHours closeHandler={handleClose} />;
            case "profileHoursPrivate":
                return <ProfileSettingsPrivateHours closeHandler={handleClose} />;
            case "userTagsList":
                return <UserTagsList />;
            case "passwordChangeForm":
                return <PasswordChangeForm closeHandler={handleClose} />;
            default:
                return <ProfileSettingsWorkHours closeHandler={handleClose} />;
        }
    }

    return (
        <>
            <MenuItem onClick={handleOpen} id="profile-settings-menuitem">Profile Settings</MenuItem>
            <Modal
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <h2>Profile Settings</h2>
                        <ToggleButtonGroup
                            value={currentForm}
                            exclusive
                            onChange={aligmentHandler}
                            aria-label="task-aligment"
                            className={classes.centerButtons}
                        >
                            <ToggleButton value="profileHoursWork" aria-label="profile hours" color="primary">
                                Work Hours
                            </ToggleButton>
                            <ToggleButton value="profileHoursPrivate" aria-label="profile hours" color="primary">
                                Private Hours
                            </ToggleButton>
                            <ToggleButton value="userTagsList" aria-label="user tags list" color="primary">
                                User Tags List
                            </ToggleButton>
                            {socialAccount ? "" :
                                <ToggleButton value="passwordChangeForm" aria-label="change password" color="primary">
                                    Password Change Form
                                </ToggleButton>
                            }
                        </ToggleButtonGroup>
                        {renderForm(currentForm)}
                    </div>
                </Fade>
            </Modal>
        </>
    );

}
