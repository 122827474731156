import React, { useState } from 'react';
import { List, ListItem, ListItemText, ListItemIcon, Container, IconButton,} from "@material-ui/core";
import { CheckBox, Edit } from "@material-ui/icons";
import UserTagForm from "./UserTagForm"

export default ({ tags, updateTag, addNewTag, patchAnnualColor, annualColor }) => {

    const [showForm, setShowForm] = useState(false)
    const [state, setState] = useState({
        name: "",
        color: "",
        id: "",
        tagFunction: ""
    })


    const updateTagHandler = (tag) => {
        setState({
            ...state,
            name: tag.name,
            color: tag.color,
            id: tag.id,
            tagFunction: updateTag
        })
        setShowForm(true)
    }

    const updateAnnualColor = () => {
        setState({
            ...state,
            name: 'Annual',
            color : annualColor,
            tagFunction: patchAnnualColor,
        })
        setShowForm(true)
    }

    const closeFormHandler = () => {
        setShowForm(false)
    }

    return (
        <div>
            {
                showForm ?
                    <UserTagForm tagName={state.name} id={state.id} color={state.color} apiFunction={state.tagFunction} closeFormHandler={closeFormHandler} />
                    :
                    <Container component="main" maxWidth="xs">
                        <List>
                            <ListItem key='annual'>
                                <ListItemIcon>
                                    <CheckBox style={{color: annualColor}} />
                                </ListItemIcon>
                                <ListItemText>
                                    Annual
                                </ListItemText>
                                <ListItemIcon>
                                    <IconButton onClick={() => updateAnnualColor()}>
                                        <Edit />
                                    </IconButton>
                                </ListItemIcon>
                            </ListItem>
                            {tags.map((tag) => (
                                <ListItem key={tag.id}>
                                    <ListItemIcon>
                                        <CheckBox style={{ color: tag.color }} />
                                    </ListItemIcon>
                                    <ListItemText>
                                        {tag.name}
                                    </ListItemText>
                                    <ListItemIcon>
                                        <IconButton onClick={() => updateTagHandler(tag)}>
                                            <Edit />
                                        </IconButton>
                                    </ListItemIcon>
                                </ListItem>
                            )).sort((prevTag, tag) => (prevTag.key - tag.key))}
                        </List>
                        
                    </Container>
            }
        </div>
    )

}