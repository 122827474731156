import React from 'react'
import GoogleLogin from 'react-google-login'

const GoogleSocialAuth = ({ googleLoginHandler }) => {
    const googleResponse = (response) => {
        googleLoginHandler(response.accessToken)
    }

    return (
        <div className="App">
            <h1>LOGIN WITH GOOGLE</h1>

            <GoogleLogin
                clientId="722307225531-sp497418husi6r6rjrhe24451sugrvsq.apps.googleusercontent.com"
                buttonText="LOGIN WITH GOOGLE"
                onSuccess={googleResponse}
                onFailure={googleResponse}
            />
        </div>
    )
}

export default GoogleSocialAuth