import React, { useEffect, useState } from 'react';
import { Modal, Fade, Backdrop, } from "@material-ui/core";
import useStyles from "./styles/ModalStyles";
import SmartTaskDetails from "../containers/SmartTaskDetails";
import NormalTaskDetails from "../containers/NormalTaskDetails";
import AnnualDetails from "../containers/AnnualDetails";
import { serializeTaskDetails, serializeAnnualDetails } from "../redux/actions/utils";
import { API } from "../globals";

export default ({ open, closeHandler, taskId, taskType }) => {

    const [state, setState] = useState({
        "taskParts": [],
        "manual": ""
    })

    const classes = useStyles()

    useEffect(() => {
        if (taskType === 'task') {
            async function fetchTaskDetails() {
                const token = localStorage.getItem("token")
                let headers = { "Content-Type": "application/json" }
                if (token) {
                    headers["Authorization"] = `JWT ${token}`
                }
                const data = await fetch(`${API}/tasks/${taskId}`, { headers, })
                data.json().then(data => serializeTaskDetails(data))
                    .then(data => setState(data))
            };
            if (open) {
                fetchTaskDetails()
            };
        } else {
            async function fetchAnnualDetails() {
                const token = localStorage.getItem('token')
                let headers = { "Content-Type": "application/json" }
                if (token) {
                    headers["Authorization"] = `JWT ${token}`
                }
                const data = await fetch(`${API}/annuals/view/${taskId}`, { headers, })
                data.json().then(data => serializeAnnualDetails(data))
                    .then(data => setState(data))
            };
            if (open) {
                fetchAnnualDetails();
            };
        };
    }, [open, taskId, taskType])

    return (
        <>
            <Modal
                className={classes.modal}
                open={open}
                onClose={closeHandler}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        {state.manual && taskType === 'task' ? <NormalTaskDetails data={state} closeHandler={closeHandler} /> : ""}
                        {state.taskParts && taskType === 'task' ? <SmartTaskDetails data={state} closeHandler={closeHandler} /> : ""}
                        {taskType === 'annual' ? <AnnualDetails data={state} closeHandler={closeHandler} /> : ""}
                    </div>

                </Fade>
            </Modal>

        </>
    );

};