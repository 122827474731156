import React, { useState } from 'react';
import { Popover, IconButton, TextField, Grid, Container, Button, CssBaseline } from "@material-ui/core";
import { CheckBox } from "@material-ui/icons"
import { GithubPicker } from "react-color"
import useStyles from "./styles/FormStyles"

export default ({ apiFunction, tagName, id, color, closeFormHandler }) => {

    const classes = useStyles()
    const [state, setState] = useState({
        name: tagName,
        color: color,
    })
    const [pickerAnchor, setPickerAnchor] = useState(null)

    const handleOpenPicker = (event) => {
        setPickerAnchor(event.currentTarget);
    }

    const handleClosePicker = () => {
        setPickerAnchor(null);
    }

    const handleColorChange = (color) => {
        setState({
            ...state,
            color: color.hex
        })
    }

    const onChangeHandler = ({ target: { name, value } }) => {
        setState(prevState => ({
            ...prevState,
            [name]: value
        }))
    }

    const onSubmitHandler = (event) => {
        event.preventDefault()
        if (id === "") {
            apiFunction(state)
        } else {
            const packToSend = {
                ...state,
                id: id
            }
            apiFunction(packToSend)
        }
        closeFormHandler()
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <form className={classes.form} onSubmit={onSubmitHandler}>
                    <Grid item xs={12} style={{ display: "flex" }}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="tag_name"
                            label="Tag name"
                            onChange={onChangeHandler}
                            value={state.name}
                            name="name"
                            InputLabelProps={{
                                shrink: true
                            }}
                            disabled={true}
                        />
                        <IconButton onClick={handleOpenPicker}>
                            <CheckBox style={{ color: state.color }} />
                        </IconButton>
                        <Popover
                            open={Boolean(pickerAnchor)}
                            anchorEl={pickerAnchor}
                            onClose={handleClosePicker}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "center",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "center",
                            }}
                        >
                            <GithubPicker onChange={handleColorChange} triangle="hide"/>
                        </Popover>
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        className={classes.submit}
                        color="primary"
                        id="user-tag-submit-button"
                    >
                        Submit
                    </Button>
                </form>
            </div>
        </Container>
    )
}