import React from 'react';
import './App.css';
import "react-big-calendar/lib/css/react-big-calendar.css"
import { Provider } from 'react-redux';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom"
import store from "./redux/store"
import Welcome from "./containers/Welcome"
import SignUpForm from "./containers/SignupForm"


const NotFound = () => (<h1>404</h1>)

function App() {


return (
  <div className="App">
    <Router>
    <Provider store={store}>
      <Switch>
      <Route exact path="/" component={Welcome} />
      <Route exact path="/sign-up" component={SignUpForm} />
      <Route component={NotFound} />
      </Switch>
    </Provider>
    </Router>
  </div>
);
}



export default App;
