import React from 'react'
import LoginForm from "../containers/LoginForm"
import Calendar from "../containers/Calendar"
export default ({auth}) => {

    return(
        <div>
            {auth.isAuthenticated ? <Calendar />: <LoginForm />}
        </div>
    )
 }