import React, { useState } from 'react'
import useStyles from "./styles/FormStyles"
import { Button, CssBaseline, TextField, Box, Container, Typography } from "@material-ui/core"
import Copyright from "./Copyright"

export default ({ signupHandler }) => {
    const [state, setState] = useState({
        email: "",
        password: "",
        repeatPassword: "",
        errors: ""
    });

    const onChangeHandler = ({ target: { name, value } }) => {
        setState(prevState => ({
            ...prevState,
            [name]: value
        }))
    };

    const onSubmitHandler = event => {
        event.preventDefault();
        if (state.password === state.repeatPassword) {
            signupHandler({
                username: state.email,
                email: state.email,
                password: state.password
            })
        } else {
            setState(prevState => ({
                ...prevState,
                errors: "Passwords not matching"
            }))
        }
    };

    const classes = useStyles()


    return (
        <div>
            <h1 style={{margin: "2%"}}>LOGO</h1>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    <Typography component="h1" variant="h5">
                        Sign Up
            </Typography>
                    <form className={classes.form} onSubmit={onSubmitHandler}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            name="email"
                            label="email"
                            type="email"
                            value={state.email}
                            onChange={onChangeHandler}
                            autoFocus
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="password"
                            name="password"
                            label="Password"
                            type="password"
                            value={state.password}
                            onChange={onChangeHandler}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="repeatPassword"
                            name="repeatPassword"
                            label="Repeat password"
                            type="password"
                            value={state.repeatPassword}
                            onChange={onChangeHandler}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            id="singup-submit-button">
                            Sign Up!
                </Button>
                    </form>
                </div>
                <Box mt={8}>
                    <Copyright />
                </Box>
            </Container>
        </div>
    )

}