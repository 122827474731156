import React, { useState } from 'react';
import { Container, Button, CssBaseline, TextField, Grid, } from '@material-ui/core';
import useStyles from './styles/FormStyles';

export default ({ addAnnualToAPI ,closeHandler }) => {

    const [state, setState] = useState({
        title: "",
        date: "",
    });

    const serializeState = () => ({
        title: state.title,
        day: new Date(state.date).getDate(),
        month: new Date(state.date).getMonth() + 1,
    })

    const onSubmitHandler = (event) => {
        event.preventDefault()
        addAnnualToAPI(serializeState(state))
        closeHandler()
    }

    const onChangeHandler = ({ target: { name, value } }) => {
        setState(prevState => ({
            ...prevState,
            [name]: value
        }))
    }

    const classes = useStyles()

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <form className={classes.form} onSubmit={onSubmitHandler}>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="title"
                            label="Title"
                            onChange={onChangeHandler}
                            value={state.title}
                            name="title"
                            autoFocus
                        />
                    </Grid>
                    <Grid item xs={12}>
                    <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="date"
                            label="Date"
                            onChange={onChangeHandler}
                            value={state.date}
                            name="date"
                            type="date"
                            InputLabelProps={{
                                shrink: true
                            }}
                        />
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        id="smart-task-submit-button"
                    >
                        Add annual event
                    </Button>
                </form>
            </div>
        </Container>
    )
    // return(
    //     <h2>chuj</h2>
    // )
}
