import React, { useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, TableFooter, TablePagination } from '@material-ui/core';
import { Done, Delete } from '@material-ui/icons';
import AreYouSureModal from './AreYouSureModal';
import EditSmartTaskModal from './EditSmartTaskModal';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        fontSize: 14,
    },
    body: {
        fontSize: 14,
        width: "auto",
    }
}))(TableCell);


const useStyles = makeStyles({
    table: {
        minWidth: "100%",
    },
    hidden: {
        display : 'none',
    },
})

const deleteButton = ({ openHandler }) => (<Button variant="contained" color="secondary" startIcon={<Delete />} onClick={openHandler} id="delete-task">Delete</Button>)

const isPast = (part) => (new Date() >= createDateTime(part))

const createDateTime = (part) => {
    const day = part.date.slice(8, 10)
    const month = part.date.slice(5, 7) - 1
    const year = part.date.slice(0, 4)
    const hours = part.end.slice(0, 2)
    const minutes = part.end.slice(3, 5)

    return new Date(year, month, day, hours, minutes)
}

export default ({ deleteTaskFromAPI, data, closeHandler }) => {
    const classes = useStyles();

    const [page,setPage] = useState(0);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }

    const apiAsyncFunction = () => {
        deleteTaskFromAPI(data.taskId)
        closeHandler()
    }

    return (

        <TableContainer>
            <Table className={classes.table}>
                <TableBody>
                    <TableRow>
                        <StyledTableCell align="center" style={{ width: "100%" }}>Title: {data.title}</StyledTableCell>
                    </TableRow>
                </TableBody>
            </Table>
            <Table className={classes.table}>
                <TableBody>
                    <TableRow>
                        <StyledTableCell align="center">Date: {data.dateFrom} - {data.dateTo}</StyledTableCell>
                    </TableRow>
                </TableBody>
            </Table>
            <Table className={classes.table}>
                <TableBody>
                    <TableRow>
                        <StyledTableCell align="center">Description: {data.description}</StyledTableCell>
                    </TableRow>
                </TableBody>
            </Table>
            <Table className={classes.table}>
                <TableBody>
                    <TableRow>
                        <StyledTableCell align="left">Priority: {data.translatedPriority}</StyledTableCell>
                        <StyledTableCell align="left">Duration: {data.duration}</StyledTableCell>
                        <StyledTableCell align="left">Max part lenght: {data.maxPartLength}</StyledTableCell>
                    </TableRow>
                </TableBody>
            </Table>
            <Table className={classes.table} aria-label="smart-task-parts-dates">
                <TableHead>
                    <TableRow>
                        <StyledTableCell align="left">Done</StyledTableCell>
                        <StyledTableCell align="left">Date</StyledTableCell>
                        <StyledTableCell align="left">Start</StyledTableCell>
                        <StyledTableCell align="left">End</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.taskParts.slice(page * 5, page * 5 + 5).map((part) => (
                        <TableRow key={part.id}>
                            <StyledTableCell>{isPast(part) ? <Done color="primary" /> : ''}</StyledTableCell>
                            <StyledTableCell>{part.date}</StyledTableCell>
                            <StyledTableCell>{part.start.slice(0, -3)}</StyledTableCell>
                            <StyledTableCell>{part.end.slice(0, -3)}</StyledTableCell>
                        </TableRow>
                    ))}
                </TableBody>
                <TableFooter className={data.taskParts.length > 5 ? null : classes.hidden}>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[5]}
                            rowsPerPage={5}
                            page={page}
                            count={data.taskParts.length}
                            onChangePage={handleChangePage}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
            <Table className={classes.table}>
                <TableBody>
                    <TableRow>
                        <StyledTableCell align="left" style={{ border: "none" }}><EditSmartTaskModal taskData={data} closeHandler={closeHandler} /></StyledTableCell>
                        <StyledTableCell align="center" style={{ border: "none" }}></StyledTableCell>
                        <StyledTableCell align="right" style={{ border: "none" }}>
                            <AreYouSureModal TriggerButton={deleteButton} apiAsyncFunction={apiAsyncFunction} />
                        </StyledTableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>

    )
}