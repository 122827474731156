import React from 'react';
import { Modal, Fade, Backdrop, Button, Grid } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import useStyles from './styles/ModalStyles';

export default ({ open, closeHandler }) => {

    const classes = useStyles();



    const handleClose = () => {
        closeHandler();
    };

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
                style={{
                    display : 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <Grid item xs={12}>
                        <h2>Operation Failed</h2>
                        </Grid>
                        <Grid item xs={12}>
                                <Button variant="contained" color="default" startIcon={<Close />} onClick={handleClose} >Close</Button>
                        </Grid>
                    </div>
                </Fade>
            </Modal>
        </div>
    )

}