export const normalTaskSerializer = (data) => ({
    name: data.name,
    date_from: data.date,
    date_to: data.date,
    description: data.description,
    duration: data.taskStart,
    priority: 4,
    tag: data.tag,
    max_part_length: data.taskEnd,
    parted: false,
    force_override: data.force_override,
    days: {
        0: true,
        1: true,
        2: true,
        3: true,
        4: true,
        5: true,
        6: true,
    },
    manual: true,
    task_type: data.task_type,
})