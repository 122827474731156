import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { Table, TableBody, TableCell, TableContainer, TableRow, Button } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import AreYouSureModal from './AreYouSureModal';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        fontSize: 14,
    },
    body: {
        fontSize: 14,
        width: "auto",
    }
}))(TableCell);


const useStyles = makeStyles({
    table: {
        minWidth: "100%",
    }
})

const deleteButton = ({ openHandler }) => (<Button variant="contained" color="secondary" startIcon={<Delete />} onClick={openHandler} id="delete-task">Delete</Button>)


export default ({ deleteAnnualFromAPI, data, closeHandler }) => {
    const classes = useStyles();

    const apiAsyncFunction = () => {
        deleteAnnualFromAPI(data.taskId)
        closeHandler()
    }

    return (

        <TableContainer>
            <Table className={classes.table}>
                <TableBody>
                    <TableRow>
                        <StyledTableCell align="center" style={{ width: "100%" }}>Title: {data.title}</StyledTableCell>
                    </TableRow>
                </TableBody>
            </Table>
            <Table className={classes.table}>
                <TableBody>
                    <TableRow>
                        <StyledTableCell align="center">Date: {data.date}</StyledTableCell>
                    </TableRow>
                </TableBody>
            </Table>
            <Table className={classes.table}>
                <TableBody>
                    <TableRow>
                        <StyledTableCell align="left" style={{ border: "none" }}>EDIT</StyledTableCell>
                        <StyledTableCell align="center" style={{ border: "none" }}></StyledTableCell>
                        <StyledTableCell align="right" style={{ border: "none" }}>
                            <AreYouSureModal TriggerButton={deleteButton} apiAsyncFunction={apiAsyncFunction} />
                        </StyledTableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>

    )
}