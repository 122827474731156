import { TASK_ERROR, TASK_FETCHED, TASK_FETCHING, TASK_ADD,} from "../actions/calendar"
import { _ } from "../../globals"

const initState = {
    loading: false,
    tasks: [],
    error: "",
}

export default (state = initState, action) => {
    switch (action.type) {
        case TASK_ERROR:
            return { ...state, loading: false, error: action.payload }
        case TASK_ADD:
            let tasksCopy = [...state.tasks, ...action.payload]
            tasksCopy = _.orderBy(tasksCopy, "date", "asc")
            return { ...state, loading: false, tasks: tasksCopy }
        case TASK_FETCHING:
            return { ...state, loading: true }
        case TASK_FETCHED:
            return { ...state, loading: false, tasks: action.payload }
        default:
            return state
    }
}